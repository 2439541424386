import {} from 'jquery-ujs';
window.$=window.jQuery=jQuery;

$(document).ready(function () {
  var remoteLinks = document.querySelectorAll('a[data-remote=\'true\']');

  for (var i = 0; i < remoteLinks.length; i++) {
    var link = remoteLinks[i];
    link.dataset.url = link.href;
    link.setAttribute('href', 'javascript:void(0);');
  }

  $.rails.href = function(el) {
    var element = el[0];
    if (element.dataset) {
      var url = element.dataset.url || element.dataset.href;
      if (url) { return url; }
    }
    return element.href;
  }
});